'use strict'

###* @ngdoc object
 # @name mundomosa
 # @description

###
angular
  .module 'mundomosa', [
    'ngAria'
    'ngMaterial'
    'ngMessages'
    'ngAnimate'
    'ngSanitize'
    'ngCookies'
    'pascalprecht.translate'
    'ui.router'
    'restangular'
    'tmh.dynamicLocale'
    'angular.filter'
    'l42y.sprintf'
    'angular-oauth2'
    'home'
    'mundoBootstrap'
    'mundoAuthentication'
    'mundoSocket'
    'mundoComponents'
    'mundoMap'
    'md.data.table'
    'dcbImgFallback'
    'lodash'
    'formly'
    'formlyMaterial'
    'ngCsv'
    'mundoAdmin'
    'mundoReporting'
    'ct.ui.router.extras.dsr'
    'rt.debounce'
    'angularMoment'
    'mundoUtils'
    'ngMaterialDatePicker'
    'angular-humanize-duration'
    'ngOrderObjectBy'
    'scDateTime'
    'platypus.jsonviewer'
    'doubleScrollBars'
    'inline'
    'ui.gravatar'
    'angular-bind-html-compile'
    'vs-repeat'
    'uuid4'
    'ng-file-model'
    'cfp.hotkeys'
    angularDragula(angular)
    'mundoNotifications'
    'mundoNavigation'
    'mundoBranding'
    'mundoActions'
    'mundoData'
    'mundoAudit'
    'mundoSettings'
    'mundoLive'
    'toastr'
    'angularUtils.directives.dirPagination'
  ]
  .constant 'translations', {}
  .config [
    '$logProvider'
    '$locationProvider'
    '$translateProvider'
    '$httpProvider'
    '$mdInkRippleProvider'
    'OAuthProvider'
    'OAuthTokenProvider'
    'RestangularProvider'
    'formlyConfigProvider'
    'gravatarServiceProvider'
    'mundoConfiguration'
    '$mdDateLocaleProvider'
    '$qProvider'
    'paginationTemplateProvider'
    (
      $logProvider
      $locationProvider
      $translateProvider
      $httpProvider
      $mdInkRippleProvider
      OAuthProvider
      OAuthTokenProvider
      RestangularProvider
      formlyConfigProvider
      gravatarServiceProvider
      mundoConfiguration
      $mdDateLocaleProvider
      $qProvider
      paginationTemplateProvider
    ) ->
      $logProvider
        .debugEnabled mundoConfiguration.debug

      $locationProvider
        .html5Mode mundoConfiguration.location.html5Mode

      $locationProvider.hashPrefix ""

      $qProvider.errorOnUnhandledRejections false

      $httpProvider
        .interceptors
        .push [
          '$rootScope'
          '$q'
          '$timeout'
          '$log'
          '$injector'
          ($rootScope, $q, $timeout, $log, $injector) ->
            return responseError: (rejection) ->
              $log.debug 'Request rejected:', rejection

              if (rejection.status == 401) and rejection.data? and
              ((rejection.data.error == 'invalid_grant') or (rejection.data.error == 'invalid_token'))
                try
                  if not $rootScope.refreshingToken
                    $rootScope.$emit 'mundo:authentication:token:refresh'

                  return new Promise (resolve, reject) ->
                    http = $injector.get '$http'
                    listeners = []

                    listener = $rootScope.$on 'mundo:authentication:success', (event, data) ->
                      (x() for x in listeners)
                      resolve(http rejection.config)
                    listeners.push listener

                    listener = $rootScope.$on 'mundo:authentication:error', (event, data) ->
                      (x() for x in listeners)
                      reject($q.reject rejection)
                    listeners.push listener
                catch
                  $rootScope
                    .$broadcast 'oauth:error', rejection

              return $q.reject rejection
        ]

      $translateProvider
        .useSanitizeValueStrategy null
        .preferredLanguage mundoConfiguration.translation.preferredLanguage
        .useLoader 'mundoTranslationLoader', {}
        .useMissingTranslationHandler('mundoTranslationHandlerFactory')

      OAuthProvider
        .configure mundoConfiguration.oauth

      OAuthTokenProvider
        .configure
          name: 'token'
          options:
            secure: mundoConfiguration.secureToken
            # expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1))

      RestangularProvider
        .setBaseUrl mundoConfiguration.oauth.baseUrl + '/api'

      # RestangularProvider
      #   .setRestangularFields
      #     route: 'apiUrl'

      # RestangularProvider.addResponseInterceptor((data, operation) ->
      #   if operation == 'getList'
      #     extractedData = data.results
      #     extractedData.error = data.error
      #     extractedData.count = data.count
      #   else
      #     extractedData = data

      #   return extractedData

      RestangularProvider.addResponseInterceptor (data, operation) ->
        if operation == 'getList'
          extractedData = if data.results then data.results else data
          extractedData.error = if data.error then data.error else null
          extractedData.count = if data.count then data.count else null
          if !angular.isArray extractedData
            copyOfExtractedData = angular.copy extractedData
            extractedData = []
            angular.forEach copyOfExtractedData, (value, key) ->
              if key != 'count' && key != 'error'
                extractedData.push {value: value, id: key, route: null}
        else
          extractedData = data

        extractedData

      # Formly: New Color Type #
      formlyConfigProvider.setType
        name: 'color',
        template: '
        <md-input-container class="ng-scope md-input-has-placeholder md-input-has-value custom-color-formly-type">
          <label for="{{::id}}">{{options.templateOptions.label}}</label>
          <input type="color" id="{{::id}}" name="{{::id}}" class="form-control" ng-model="model[options.key]" />
        </md-input-container>
        '

      # Formly: Chips with autocomplete #
      formlyConfigProvider.setType
        name: 'modelchips'
        templateUrl: 'mundo-admin/views/modelchips.tpl.html'
        controller: [
          '$scope'
          ($scope) ->
            init = ->
              $scope.id = $scope.options.key
              $scope.model = $scope.model or {}
              $scope.model[$scope.options.key] = $scope.model[$scope.options.key] or []
              return

            $scope.filterTheAutoComplete  = ((searchText, labelProp) ->
              autoCompleteOptions = []

              if not (searchText? and searchText.length)
                return $scope.options.templateOptions.options

              for option in $scope.options.templateOptions.options
                  if option[labelProp].toLowerCase().indexOf(searchText.toLowerCase()) > -1
                    autoCompleteOptions.push option

              return autoCompleteOptions
            )

            init()
        ]

      gravatarServiceProvider.defaults =
        size: 100
        'default': 'identicon'

      $mdInkRippleProvider.disableInkRipple()

      $mdDateLocaleProvider.formatDate = (date) ->
        return if date then moment(date).format('D/MM/YYYY') else ''
      $mdDateLocaleProvider.parseDate = (dateString) ->
        m = moment(dateString, 'D/MM/YYYY', true)
        if m.isValid() then m.toDate() else new Date(NaN)

      paginationTemplateProvider.setPath 'mundo-utils/views/mundo-pagination.tpl.html'
  ]
  .run [
    '$rootScope'
    '$log'
    '$state'
    '$timeout'
    '$http'
    'OAuth'
    'OAuthToken'
    'MyUser'
    'UserPermissions'
    '$translationCache'
    'Restangular'
    '$translate'
    'translations'
    'formlyValidationMessages'
    '$window'
    '$interval'
    'hotkeys'
    (
      $rootScope
      $log
      $state
      $timeout
      $http
      OAuth
      OAuthToken
      MyUser
      UserPermissions
      $translationCache
      Restangular
      $translate
      translations
      formlyValidationMessages
      $window
      $interval
      hotkeys
    ) ->
      $timeout () ->
        $rootScope.applicationLoaded = true
      , 500

      humanizeDuration = $window.humanizeDuration
      $window.humanizeDuration = humanizeDuration.humanizer
        delimiter: ' '
        spacer: ''
        language: 'shortEn'
        languages:
          shortEn:
            y: (c) -> 'y'
            mo: (c) -> 'mo'
            w: (c) -> 'w'
            d: (c) -> 'd'
            h: (c) -> 'h'
            m: (c) -> 'm'
            s: (c) -> 's'
            ms: (c) -> 'ms'
            decimal: '.'

      authenticationSuccessEventName = 'mundo:authentication:success'
      authenticationErrorEventName = 'mundo:authentication:error'
      authenticationLogoutEventName = 'mundo:authentication:logout'
      authenticationTokenRefreshEventName = 'mundo:authentication:token:refresh'
      authenticationUserReloadEventName = 'mundo:authentication:user:reload'
      authenticationUserPermissionsLoadedEventName = 'mundo:authentication:user:permissions:loaded'
      $rootScope.refreshingToken = false

      $rootScope
        .$on '$stateChangeSuccess', (event, toState, toParams, fromState, fromParams) ->
          # state.data.navigation should be used,
          # otherwise child state does not inherit the value
          $rootScope.showNavigation = not (toState.data and (toState.data.navigation == false))

          if toState.data and toState.data.anonymous
            $log.debug 'Auth: Bypassing credentials check due to anonymous state'
          else
            $log.debug 'Auth: Performing credentials check during state transition'

            # Save next state and params in $rootScope,
            # in order to be able to redirect after a login (after an oauth failure)
            $rootScope.nextState =
              state: toState.name
              params: toParams

            if $rootScope.user
              $log.debug 'Auth: User present, authentication check passed'

              $rootScope
                .$broadcast authenticationSuccessEventName

            else if OAuth.isAuthenticated()
              $log.debug 'Auth: Credentials present, triggering user fetch'

              $rootScope
                .$broadcast authenticationUserReloadEventName

            else
              $log.debug 'Auth: Authentication check failed, triggering authentication error'
              event.preventDefault()

              $rootScope
                .$broadcast authenticationErrorEventName

      $rootScope
        .$on authenticationTokenRefreshEventName, (event, rejection) ->
          if OAuth.isAuthenticated() and !$rootScope.refreshingToken
            $rootScope.refreshingToken = true
            OAuth
              .getRefreshToken()
              .then () ->
                $rootScope.refreshingToken = false

                $log.debug 'Auth: OAuth token refreshed, triggering user refresh'

                $rootScope
                  .$broadcast authenticationUserReloadEventName
              , () ->
                $rootScope.refreshingToken = false
                $rootScope
                  .$broadcast authenticationErrorEventName, rejection
          else
            $log.debug 'Auth: OAuth token refresh lock already set'

      $rootScope
        .$on 'oauth:error', (event, rejection) ->
          if rejection.data.error == 'invalid_token'
            $log.debug 'Auth: OAuth token invalid, attempting refresh'
            $rootScope
              .$emit authenticationTokenRefreshEventName

          else if rejection.data.error == 'invalid_grant'
            $log.debug 'Auth: OAuth grant invalid, triggering authentication error'

            $rootScope
              .$broadcast authenticationErrorEventName, rejection

      $rootScope
        .$on authenticationErrorEventName, (event, data) ->
          $log.debug 'Auth: Authentication error detected, redirecting to login state'
          $state.go 'authentication.init'

      $rootScope
        .$on authenticationUserReloadEventName, (event, data) ->
          $log.debug 'Auth: Fetching user'

          MyUser.one()
            .get()
            .then (result) ->
              $log.debug 'Auth: User fetched, authentication check passed', result

              $rootScope
                .user = result

              if !result.userContexts.length or result.userContexts.length <= 0
                $log.debug 'Auth: No valid userContext detected, logging out user'
                $state.go 'authentication.logout'
                return

              # if there is only one userContext, set it as the activeUserContext
              if result.userContexts.length == 1
                $rootScope.user.activeUserContext = result.userContexts[0]

              if !$rootScope.user.activeUserContext and
                result.userContexts.length? and
                result.userContexts.length > 0
                  $rootScope.user.activeUserContext = result.userContexts[0]

              if not $rootScope.hasPermission?
                $rootScope.hasPermission = (permission) ->
                  return UserPermissions.check permission

                UserPermissions.reloadPermissions().then () ->
                  $rootScope
                    .$broadcast authenticationUserPermissionsLoadedEventName

              if not $rootScope.translationsLoaded?
                $rootScope.translationsLoaded = true

                locale = $rootScope.user.locale
                languageKey = switch locale
                  when 'en' then 'en_EN'
                  when 'fr' then 'fr_FR'
                  when 'nl' then 'nl_BE'

                $translate.use languageKey

                Restangular
                  .service 'translations'
                  .one 'units'
                  .get
                    t: Math.round((new Date()).getTime() / 1000)
                    locale: locale
                  .then (data) ->
                    if not translations[languageKey]?
                      translations[languageKey] = {}

                    angular.merge translations[languageKey], data.plain()
                    $translate.refresh languageKey

              $rootScope
                .$broadcast authenticationSuccessEventName

      $rootScope
        .$on authenticationSuccessEventName, (event, data) ->
          if $rootScope.authenticationRefreshTimer
            return

          # Determine refresh interval based on expires_in property of received OAuth2 token response,
          # defaulting to a default value if it doesn't exist
          interval = 900 * 1000

          try
            interval = Math.round(OAuthToken.getToken().expires_in * 0.9) * 1000
          catch error
            # Do nothing

          $log.debug "Auth: Starting authentication refresh timer with interval of #{interval} ms"

          $rootScope.authenticationRefreshTimer = $interval () ->
            $log.debug 'Auth: Triggering periodic authentication refresh'

            $rootScope
              .$emit authenticationTokenRefreshEventName
          , interval

      $rootScope
        .$on authenticationErrorEventName, (event, data) ->
          if not $rootScope.authenticationRefreshTimer
            return

          $log.debug 'Auth: Disabling authentication refresh timer'

          $interval
            .cancel $rootScope.authenticationRefreshTimer

          delete $rootScope.authenticationRefreshTimer

      $rootScope
        .$on authenticationLogoutEventName, (event, data) ->
          delete $rootScope.user
          delete $rootScope.hasPermission
          delete $rootScope.translationsLoaded

      formlyValidationMessages.addStringMessage('required', 'This field is required')
      formlyValidationMessages.addStringMessage('external', 'This field did not pass server validation')

      # HOTKEYS

      hotkeys.add
        combo: "g l"
        description: "Go to Live"
        callback: () ->
          $state.go 'live'

      hotkeys.add
        combo: "g h"
        description: "Go to Home"
        callback: () ->
          $state.go 'home'

      hotkeys.add
        combo: "g r"
        description: "Go to Reporting"
        callback: () ->
          $state.go 'reporting'

      $rootScope.keys = Object.keys
  ]
  .value 'scDateTimeConfig',
    defaultTheme: 'material'
    autosave: false
    displayTwentyfour: true
  .value 'scDateTimeI18n',
    previousMonth: "Vorige Maand",
    nextMonth: "Volgende Maand",
    incrementHours: "Vermeerdering uren",
    decrementHours: "Vermindering uren",
    incrementMinutes: "Vermeerdering Minuten",
    decrementMinutes: "Vermindering Minuten",
    switchAmPm: "Wijzig AM/PM",
    now: "Nu",
    cancel: "annuleren",
    save: "Opslaan",
    weekdays: ['Z', 'M', 'D', 'W', 'D', 'V', 'Z'],
    switchTo: 'Wijzig naar',
    clock: 'Klok',
    calendar: 'Kalender'
  .factory 'mundoTranslationLoader', [
    '$http'
    '$q'
    '$log'
    'translations'
    'mundoConfiguration'
    ($http, $q, $log, translations, mundoConfiguration) ->
      return (options) ->
        deferred = $q.defer()

        $http
          method: 'GET'
          url: "resources/translations/#{options.key}.json"
        .then (response) ->
          if not translations[options.key]?
            translations[options.key] = {}

          angular.merge translations[options.key], response.data
          deferred.resolve translations[options.key]
        .catch () ->
          deferred.reject options.key

        deferred.promise
  ]
